import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import { deleteLink } from '../../firestore-actions'
import { Lotura } from '../../interfaces'

interface RootState {
    links: Lotura[]
}

const mapStateToProps = (state: RootState) => {
    return { links: state.links }
}
  
const connector = connect(mapStateToProps, {})

class Loturak extends React.Component<ConnectedProps<typeof connector>>  {

    renderLinks() {
        return this.props.links.map(link => {
            return (
                <tr key={link.id}>
                    <td>{link.izena}</td>
                    <td><a href={link.url}>{link.url}</a></td>
                    <td><Link to={`/loturak/edit/${link.id}`}>Aldatu</Link></td>
                    <td><Link to="/loturak" onClick={() => {
                       if (window.confirm('Ziur zaude ezabatu nahi duzula?')) deleteLink(link.id) 
                    }}>Ezabatu</Link></td>
                </tr>
            )
        })
    }

    render() {
        return (
            <div>
                <table className="table is-bordered is-striped is-fullwidth">
                    <thead>
                        <tr>
                            <th>Titulua</th>
                            <th>Lotura</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.renderLinks() }
                    </tbody>
                </table>
                <Link to="/loturak/add" className="button">Berria</Link>
            </div>
        )
    }
}

export default connector(Loturak)