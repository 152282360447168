import React from 'react'
import { getOharra, saveOharra } from '../../firestore-actions'
import { withRouter, RouteComponentProps } from 'react-router-dom'

class Oharra extends React.Component<RouteComponentProps, { mezua: string, active: boolean, loading: boolean }>  {

    constructor(props: any) {
        super(props)

        this.state = {
            mezua: '',
            active: false,
            loading: true
        }
    }

    componentDidMount() {
        getOharra('oharra').then(snapshot => {
            const data = snapshot.data();

            this.setState({
                mezua: data?.mezua,
                active: data?.active,
                loading: false
            })
        })
    }

    save() {
        this.setState({ loading: true })
        saveOharra('oharra', this.state.mezua, this.state.active).then(() => {
            this.setState({
                loading: false
            })
        })
    }

    render() {
        return (
            <div>
                <div className="field">
                    <label className="label">Mezua</label>
                    <div className="control">
                        <textarea className="textarea" placeholder="Mezua" value={this.state.mezua} onChange={(event) => this.setState({ mezua: event.target.value})}/>
                    </div>
                </div>

                <div className="field">
                    <div className="control">
                        <label className="checkbox">
                            <input type="checkbox" checked={this.state.active} onChange={(event) => this.setState({ active: event.target.checked })} /> Oharra erakutsi aplikazioan
                        </label>
                    </div>
                </div>

                <div className="field is-grouped">
                    <div className="control">
                        <button className={ this.state.loading ? 'button is-link is-loading' : 'button is-link'} onClick={() => this.save()}>Gorde</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Oharra)