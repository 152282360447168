import { UpdateBabesleaTypes, UPDATE_EGOERA, UPDATE_MAPA, UPDATE_SPLASH, UPDATE_JAKINARAZPENAK_BANNER, UPDATE_HOME_BANNER } from './types'

export const updateSplash = (url: string): UpdateBabesleaTypes => {
    return {
        type: UPDATE_SPLASH,
        payload: url
    }
}

export const updateMapa = (url: string): UpdateBabesleaTypes => {
    return {
        type: UPDATE_MAPA,
        payload: url
    }
}

export const updateEgoera = (url: string): UpdateBabesleaTypes => {
    return {
        type: UPDATE_EGOERA,
        payload: url
    }
}

export const updateHomeBanner = (url: string): UpdateBabesleaTypes => {
    return {
        type: UPDATE_HOME_BANNER,
        payload: url
    }
}

export const updateJakinarazpenakBanner = (url: string): UpdateBabesleaTypes => {
    return {
        type: UPDATE_JAKINARAZPENAK_BANNER,
        payload: url
    }
}