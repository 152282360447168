import { combineReducers } from 'redux'
import auth_reducer from './auth-reducer'
import links_reducer from './links-reducer'
import babesleak_reducer from './babesleak-reducer'
import jakinarazpenak_reducer from './jakinarazpenak-reducer'
import bideoak_reducer from './bideoak-reducer'

export const rootReducer = combineReducers({
    signed_in: auth_reducer,
    links: links_reducer,
    babesleak: babesleak_reducer,
    jakinarazpenak: jakinarazpenak_reducer,
    bideoak: bideoak_reducer
})

export type RootState = ReturnType<typeof rootReducer>