import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import firebase from '../../firebase'
import { RootState } from '../../reducers'
import { updateSplash, updateMapa, updateEgoera, updateHomeBanner, updateJakinarazpenakBanner } from '../../actions';
import { UpdateBabesleaTypes } from '../../actions/types'

const mapStateToProps = (state: RootState) => {
    return { babesleak: state.babesleak }
}
  
const connector = connect(mapStateToProps, { updateSplash, updateMapa, updateEgoera, updateHomeBanner, updateJakinarazpenakBanner })

type Props = ConnectedProps<typeof connector>;

interface BabesleaState {
    name: string
    uploading: boolean
    progress: number
}

interface State {
    data: BabesleaState[]
}

class Babesleak extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            data: [
                {
                    name: 'splash.jpg',
                    uploading: false,
                    progress: 0
                },
                {
                    name: 'mapa.jpg',
                    uploading: false,
                    progress: 0
                },
                {
                    name: 'egoera.jpg',
                    uploading: false,
                    progress: 0
                },
                {
                    name: 'home.jpg',
                    uploading: false,
                    progress: 0
                },
                {
                    name: 'jakinarazpenak.jpg',
                    uploading: false,
                    progress: 0
                }
            ]}
    }

    uploadImage(index: number, name: string, file: File, callback: (url: string) => UpdateBabesleaTypes) {
        const task = firebase.storage().ref(name).put(file);

        this.setState({ data: this.state.data.map((data, i) => index === i ? ({ ...data, uploading: true }) : data) });

        task.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            this.setState({ data: this.state.data.map((data, i) => index === i ? ({ ...data, progress }) : data) });
        }, (error) => {
            this.setState({ data: this.state.data.map((data, i) => index === i ? ({ ...data, uploading: false, progress: 0 }) : data) });
        }, () => {
            task.snapshot.ref.getDownloadURL().then(url => {
                this.setState({ data: this.state.data.map((data, i) => index === i ? ({ ...data, uploading: false, progress: 0 }) : data) });
                callback(url);
            });
        });
    }

    renderBabesleaActions(index: number, callback: (url: string) => UpdateBabesleaTypes) {
        const data = this.state.data[index];

        if (data.uploading) {
            return <progress value={data.progress} max="100"/>
        }

        return <input type="file" onChange={(e) => this.uploadImage(index, data.name, e.target.files?.item(0)!, callback)} />
    }

    render() {
        const { splash, mapa, egoera, home, jakinarazpenak } = this.props.babesleak;
        return (
            <div>
                <table className="table is-bordered is-striped is-fullwidth">
                    <thead>
                        <tr>
                            <th>Lekua</th>
                            <th>Irudia</th>
                            <th>Aldatu</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Splash</td>
                            <td><img src={splash} style={imageStyle} alt="Splash" /></td>
                            <td>{this.renderBabesleaActions(0, this.props.updateSplash)}</td>
                        </tr>
                        <tr>
                            <td>Home</td>
                            <td><img src={home} style={imageStyle} alt="Home" /></td>
                            <td>{this.renderBabesleaActions(3, this.props.updateHomeBanner)}</td>
                        </tr>
                        <tr>
                            <td>Mapa</td>
                            <td><img src={mapa} style={imageStyle} alt="Mapa" /></td>
                            <td>{this.renderBabesleaActions(1, this.props.updateMapa)}</td>
                        </tr>
                        <tr>
                            <td>Egoera</td>
                            <td><img src={egoera} style={imageStyle} alt="Egoera" /></td>
                            <td>{this.renderBabesleaActions(2, this.props.updateEgoera)}</td>
                        </tr>
                        <tr>
                            <td>Jakinarazpenak</td>
                            <td><img src={jakinarazpenak} style={imageStyle} alt="Jakinarazpenak" /></td>
                            <td>{this.renderBabesleaActions(4, this.props.updateJakinarazpenakBanner)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

const imageStyle = {
    height: 200
}

export default connector(Babesleak)