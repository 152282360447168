import React from 'react'
import { addBideoa } from '../../firestore-actions'
import { withRouter, RouteComponentProps } from 'react-router-dom'

class BideoaAdd extends React.Component<RouteComponentProps, { title: string, url: string, latitude: number, longitude: number }>  {

    constructor(props: any) {
        super(props)

        this.state = {
            title: '',
            url: '',
            latitude: 0,
            longitude: 0
        }
    }

    addBideoa() {
        addBideoa(this.state.title, this.state.url, this.state.latitude, this.state.longitude).then(() => {
            this.props.history.push('/bideoak')
        })
    }

    render() {
        return (
            <div>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Titulua</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <input className="input" type="text" placeholder="Titulua" value={this.state.title} onChange={(event) => this.setState({ title: event.target.value})}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">URL</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <input className="input" type="text" placeholder="URL" value={this.state.url} onChange={(event) => this.setState({url: event.target.value})} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <label className="label">Latitude</label>
                            <div className="control">
                                <input className="input" type="text" placeholder="URL" value={this.state.latitude} onChange={(event) => this.setState({latitude: parseFloat(event.target.value)})} />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Longitude</label>
                            <div className="control">
                                <input className="input" type="text" placeholder="URL" value={this.state.longitude} onChange={(event) => this.setState({longitude: parseFloat(event.target.value)})} />
                            </div>
                        </div>
                    </div>
                </div> 

                <div className="field is-horizontal">
                    <div className="field-label"></div>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <button className="button is-link" onClick={() => this.addBideoa()}>Gehitu</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(BideoaAdd)