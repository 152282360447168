import React from 'react'

export interface Props {
    firebase: any
}

class Main extends React.Component<Props>  {
    render() {
        return <img src="/korrika.jpg" alt="logo" />
    }
}

export default Main