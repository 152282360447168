import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import { deleteJakinarazpena } from '../../firestore-actions'
import moment from 'moment'
import { RootState } from '../../reducers'

const mapStateToProps = (state: RootState) => {
    return { jakinarazpenak: state.jakinarazpenak }
}
  
const connector = connect(mapStateToProps, {})

class Jakinarazpenak extends React.Component<ConnectedProps<typeof connector>>  {

    renderLinks() {
        return this.props.jakinarazpenak.map(jakinarazpena => {
            return (
                <tr key={jakinarazpena.id}>
                    <td>{jakinarazpena.title}</td>
                    <td>{jakinarazpena.body}</td>
                    <td>{jakinarazpena.sent ? 'Bai' : 'Ez'}</td>
                    <td>{moment(jakinarazpena.created.toMillis()).format()}</td>
                    <td><Link to={`/jakinarazpenak/edit/${jakinarazpena.id}`}>Aldatu</Link></td>
                    <td><Link to="/jakinarazpenak" onClick={() => {
                       if (window.confirm('Ziur zaude ezabatu nahi duzula?')) deleteJakinarazpena(jakinarazpena.id) 
                    }}>Ezabatu</Link></td>
                </tr>
            )
        })
    }

    render() {
        return (
            <div>
                <table className="table is-bordered is-striped is-fullwidth">
                    <thead>
                        <tr>
                            <th>Titulua</th>
                            <th>Edukia</th>
                            <th>Bidalita</th>
                            <th>Data</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.renderLinks() }
                    </tbody>
                </table>
                <Link to="/jakinarazpenak/add" className="button">Berria</Link>
            </div>
        )
    }
}

export default connector(Jakinarazpenak)