import { UPDATE_JAKINARAZPENAK, UpdateJakinarazpenakAction } from '../actions/types'
import { Jakinarazpena } from '../interfaces'

export default (state: Jakinarazpena[] = [], action: UpdateJakinarazpenakAction) => {
    switch (action.type) {
        case UPDATE_JAKINARAZPENAK:
            return action.payload
        default:
            return state
    }
}