import React from 'react';
import './bulma.min.css';
import Login from './components/Login'
import firebase from './firebase'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import { updateSignedIn, updateLinks, updateSplash, updateMapa, updateEgoera, updateJakinarazpenak, updateBideoak, updateHomeBanner, updateJakinarazpenakBanner } from './actions/index'
import Nav from './components/Nav'
import Loturak from './components/loturak/Loturak'
import Main from './components/Main'
import LoturaAdd from './components/loturak/LoturaAdd';
import LoturaEdit from './components/loturak/LoturaEdit';
import Babesleak from './components/babesleak/Babesleak';
import Jakinarazpenak from './components/jakinarazpenak/Jakinarazpenak';
import JakinarazpenaAdd from './components/jakinarazpenak/JakinarazpenaAdd';
import JakinarazpenaEdit from './components/jakinarazpenak/JakinarazpenaEdit';
import { Bideoa, Jakinarazpena, Lotura } from './interfaces';
import Bideoak from './components/bideoak/Bideoak';
import BideoaAdd from './components/bideoak/BideoaAdd';
import BideoaEdit from './components/bideoak/BideoaEdit';
import Oharra from './components/oharra/Oharra';

const mapStateToProps = (state: RootState) => {
  return { signed_in: state.signed_in }
}

const connector = connect(mapStateToProps, { updateSignedIn, updateLinks, updateSplash, updateMapa, updateEgoera, updateJakinarazpenak, updateBideoak, updateHomeBanner, updateJakinarazpenakBanner })

class App extends React.Component<ConnectedProps<typeof connector>> {
  unregisterAuthObserver!: firebase.Unsubscribe;

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      this.props.updateSignedIn(!!user)

      if (!!user) {
       this.startListeningLinks()
       this.startListeningJakinarazpenak()
       this.startListeningBideoak()
       this.updateBabesleak()
      }
    })

    console.log(this.props.signed_in)
  }

  startListeningLinks() {
    firebase.firestore().collection('loturak').onSnapshot(snapshot => {
      const docs = snapshot.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        } as Lotura
      })
      this.props.updateLinks(docs)
    })
  }

  startListeningJakinarazpenak() {
    firebase.firestore().collection('jakinarazpenak').orderBy('created', 'desc').onSnapshot(snapshot => {
      const docs = snapshot.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        } as Jakinarazpena
      })
      this.props.updateJakinarazpenak(docs)
    })
  }

  startListeningBideoak() {
    firebase.firestore().collection('bideoak').onSnapshot(snapshot => {
      const docs = snapshot.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        } as Bideoa
      })
      this.props.updateBideoak(docs)
    })
  }

  updateBabesleak() {
    firebase.storage().ref('splash.jpg').getDownloadURL().then(this.props.updateSplash);
    firebase.storage().ref('mapa.jpg').getDownloadURL().then(this.props.updateMapa);
    firebase.storage().ref('egoera.jpg').getDownloadURL().then(this.props.updateEgoera);
    firebase.storage().ref('home.jpg').getDownloadURL().then(this.props.updateHomeBanner);
    firebase.storage().ref('jakinarazpenak.jpg').getDownloadURL().then(this.props.updateJakinarazpenakBanner);

  }

  componentWillUnmount() {
    this.unregisterAuthObserver()
  }

  render() {
    const { signed_in } = this.props

    console.log(signed_in)
    
    return (
      <Router> 
        {signed_in !== null && !signed_in &&
          <Login auth={firebase.auth()} />
        }
        {signed_in &&
          <div>
            <Nav signed_in={signed_in} />
            <section className="section">
            <div className="container">
              <Switch>
                <Route exact path="/" component={Main} />
                <Route exact path="/loturak" component={Loturak} />
                <Route exact path="/babesleak" component={Babesleak} />
                <Route path="/loturak/add" component={LoturaAdd} />
                <Route path="/loturak/edit/:id" component={LoturaEdit} />
                <Route exact path="/jakinarazpenak" component={Jakinarazpenak} />
                <Route path="/jakinarazpenak/add" component={JakinarazpenaAdd} />
                <Route path="/jakinarazpenak/edit/:id" component={JakinarazpenaEdit} />
                <Route exact path="/bideoak" component={Bideoak} />
                <Route path="/bideoak/add" component={BideoaAdd} />
                <Route path="/bideoak/edit/:id" component={BideoaEdit} />
                <Route exact path="/oharra" component={Oharra} />
              </Switch>
              </div>
          </section>
          </div>
        }
      </Router>
    );
  }
}

interface RootState {
  signed_in?: boolean
}


export default connector(App);
