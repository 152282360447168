import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import { deleteBideoa } from '../../firestore-actions'
import { RootState } from '../../reducers'

const mapStateToProps = (state: RootState) => {
    return { bideoak: state.bideoak }
}
  
const connector = connect(mapStateToProps, {})

class Bideoak extends React.Component<ConnectedProps<typeof connector>>  {

    renderLinks() {
        return this.props.bideoak.map(bideoa => {
            return (
                <tr key={bideoa.id}>
                    <td>{bideoa.title}</td>
                    <td><a href={bideoa.url}>{bideoa.url}</a></td>
                    <td><a href={`https://www.google.com/maps/search/?api=1&query=${bideoa.location.latitude},${bideoa.location.longitude}`}>{bideoa.location.latitude},{bideoa.location.longitude}</a></td>
                    <td><Link to={`/bideoak/edit/${bideoa.id}`}>Aldatu</Link></td>
                    <td><Link to="/bideoak" onClick={() => {
                       if (window.confirm('Ziur zaude ezabatu nahi duzula?')) deleteBideoa(bideoa.id) 
                    }}>Ezabatu</Link></td>
                </tr>
            )
        })
    }

    render() {
        return (
            <div>
                <table className="table is-bordered is-striped is-fullwidth">
                    <thead>
                        <tr>
                            <th>Titulua</th>
                            <th>URL</th>
                            <th>Kokapena</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.renderLinks() }
                    </tbody>
                </table>
                <Link to="/bideoak/add" className="button">Berria</Link>
            </div>
        )
    }
}

export default connector(Bideoak)