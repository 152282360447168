import React from 'react'
import { Link } from 'react-router-dom'
import firebase from '../firebase'

interface Props {
    signed_in: boolean
}

class Nav extends React.Component<Props> {
    render() {
        return (
            <nav className="navbar is-black" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <Link to="/" className="navbar-item">
                        <img src="/korrikaLogoa.png" alt="Korrika logoa" />
                    </Link>
                </div>

                <div id="navbarBasicExample" className="navbar-menu">
                    <div className="navbar-start">
                        <Link to="/loturak" className="navbar-item">
                            Loturak
                        </Link>
                        <Link to="/babesleak" className="navbar-item">
                            Babesleak
                        </Link>
                        <Link to="/jakinarazpenak" className="navbar-item">
                            Jakinarazpenak
                        </Link>
                        <Link to="/bideoak" className="navbar-item">
                            Bideoak
                        </Link>
                        <Link to="/oharra" className="navbar-item">
                            Oharra
                        </Link>
                    </div>

                    <div className="navbar-end">
                        <a href="/" className="navbar-item" onClick={() => firebase.auth().signOut()}>
                            Logout
                        </a>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Nav