import React from 'react'

export interface Props {
    auth: firebase.auth.Auth
}

class Login extends React.Component<Props, { email: string, password: string, loading: boolean, error: string}>  {
    constructor(props: Props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            loading: false,
            error: ''
        }
    }

    login() {
        const { email, password } = this.state;

        this.setState({ loading: true, error: '' });

        this.props.auth.signInWithEmailAndPassword(email, password).then(() => {
            this.setState({ loading: false })
        }).catch((error) => {
            console.error(error);

            this.setState({ loading: false, error: 'Erabiltzailea edo pasahitza ez da zuzena' })
        });

        return false;
    }

    render() {
        const { email, password, loading, error } = this.state;

        return (
            <section className="hero is-bold is-warning is-fullheight">
                <div className="hero-body">
                    <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-5-tablet is-4-desktop is-4-widescreen">
                            <form className="box" onSubmit={(e) => { e.preventDefault(); this.login() }}>
                                <div className="field">
                                    <label className="label">Emaila</label>
                                    <div className="control has-icons-left">
                                        <input type="email" placeholder="antxon.urrutia@gmail.com" className="input" required value={email} onChange={(e) => this.setState({ email: e.target.value })} />
                                        <span className="icon is-small is-left"><i className="fa fa-envelope"></i></span>
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">Pasahitza</label>
                                    <div className="control has-icons-left">
                                        <input type="password" placeholder="*******" className="input" required value={password} onChange={(e) => this.setState({ password: e.target.value })} />
                                        <span className="icon is-small is-left"><i className="fa fa-lock"></i></span>
                                    </div>
                                    { error !== '' && <p className="help is-danger">{error}</p> }
                                </div>
                                <div className="field">
                                    <button className={ `button is-info ${loading ? 'is-loading' : ''}` } >Sartu</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Login