import React from 'react'
import { saveLink } from '../../firestore-actions'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import firebase from '../../firebase'

interface RouteParams {
    id: string
}
class LoturaEdit extends React.Component<RouteComponentProps<RouteParams>, { id: string, izena: string, lotura: string }>  {

    constructor(props: any) {
        super(props)

        const { id } = this.props.match.params

        this.state = {
            id: id,
            izena: '',
            lotura: ''
        }
    }

    componentDidMount() {
        firebase.firestore().collection('loturak').doc(this.state.id).get().then(snapshot => {
            const data = snapshot.data()
            
            this.setState({
               izena: data?.izena,
               lotura: data?.url 
            })
        })
    }

    saveLink() {
        saveLink(this.state.id, this.state.izena, this.state.lotura).then(() => {
            this.props.history.push('/loturak')
        })
    }

    render() {
        return (
            <div>
                <div className="field">
                    <label className="label">Izena</label>
                    <div className="control">
                        <input className="input" type="text" placeholder="Izena" value={this.state.izena} onChange={(event) => this.setState({ izena: event.target.value})}/>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Lotura</label>
                    <div className="control">
                        <input className="input" type="text" placeholder="Lotura" value={this.state.lotura} onChange={(event) => this.setState({lotura: event.target.value})} />
                    </div>
                </div>

                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-link" onClick={() => this.saveLink()}>Gehitu</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(LoturaEdit)