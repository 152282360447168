import React from 'react'
import { saveJakinarazpena } from '../../firestore-actions'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import firebase from '../../firebase'

interface RouteParams {
    id: string
}
class JakinarazpenaEdit extends React.Component<RouteComponentProps<RouteParams>, { id: string, title: string, body: string, send: boolean }>  {

    constructor(props: any) {
        super(props)

        const { id } = this.props.match.params

        this.state = {
            id: id,
            title: '',
            body: '',
            send: false
        }
    }

    componentDidMount() {
        firebase.firestore().collection('jakinarazpenak').doc(this.state.id).get().then(snapshot => {
            const data = snapshot.data()
            
            this.setState({
               title: data?.title,
               body: data?.body 
            })
        })
    }

    save() {
        saveJakinarazpena(this.state.id, this.state.title, this.state.body, this.state.send).then(() => {
            this.props.history.push('/jakinarazpenak')
        })
    }

    render() {
        return (
            <div>
                <div className="field">
                    <label className="label">Titulua</label>
                    <div className="control">
                        <input className="input" type="text" placeholder="Titulua" value={this.state.title} onChange={(event) => this.setState({ title: event.target.value})}/>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Edukia</label>
                    <div className="control">
                        <input className="input" type="text" placeholder="Edukia" value={this.state.body} onChange={(event) => this.setState({body: event.target.value})} />
                    </div>
                </div>

                <div className="field">
                    <div className="control">
                        <label className="checkbox">
                            <input type="checkbox" onChange={(event) => this.setState({ send: event.target.checked })} /> Jakinarazpena <strong>orain</strong> bidali
                        </label>
                    </div>
                </div>

                { this.state.send &&
                    <div className="notification is-warning">ADI! Mezu hau gordetzen duzunean <strong>PUSH jakinarazpen</strong> bat bidaliko zaie Korrika aplikazioa instalatuta duten erabiltzaile guztiei.</div>
                }

                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-link" onClick={() => this.save()}>Gorde</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(JakinarazpenaEdit)