import { Lotura, Jakinarazpena, Bideoa } from "../interfaces"

export const UPDATE_SIGNED_IN = 'update_signed_in'
export const UPDATE_LINKS = 'update_links'
export const UPDATE_SPLASH = 'update_splash'
export const UPDATE_MAPA = 'update_mapa'
export const UPDATE_EGOERA = 'update_egoera'
export const UPDATE_JAKINARAZPENAK = 'update_jakinarazpenak'
export const UPDATE_BIDEOAK = 'update_bideoak'
export const UPDATE_HOME_BANNER = 'update_home_banner'
export const UPDATE_JAKINARAZPENAK_BANNER = 'update_jakinarazpenak_banner'

export interface UpdateSignedInAction {
    type: typeof UPDATE_SIGNED_IN,
    payload: boolean | undefined
}

export interface UpdateLinksAction {
    type: typeof UPDATE_LINKS,
    payload: Lotura[]
}

interface UpdateSplashAction {
    type: typeof UPDATE_SPLASH,
    payload: string
}

interface UpdateMapaAction {
    type: typeof UPDATE_MAPA,
    payload: string
}

interface UpdateEgoeraAction {
    type: typeof UPDATE_EGOERA,
    payload: string
}

interface UpdateHomeBannerAction {
    type: typeof UPDATE_HOME_BANNER,
    payload: string
}

interface UpdateJakinarazpenakBannerAction {
    type: typeof UPDATE_JAKINARAZPENAK_BANNER,
    payload: string
}

export type UpdateBabesleaTypes = UpdateSplashAction | UpdateMapaAction | UpdateEgoeraAction | UpdateHomeBannerAction | UpdateJakinarazpenakBannerAction

export interface UpdateJakinarazpenakAction {
    type: typeof UPDATE_JAKINARAZPENAK,
    payload: Jakinarazpena[]
}

export interface UpdateBideoakAction {
    type: typeof UPDATE_BIDEOAK,
    payload: Bideoa[]
}