import React from 'react'
import { addLink } from '../../firestore-actions'
import { withRouter, RouteComponentProps } from 'react-router-dom'

class LoturaAdd extends React.Component<RouteComponentProps, { izena: string, lotura: string }>  {

    constructor(props: any) {
        super(props)

        this.state = {
            izena: '',
            lotura: ''
        }
    }

    addLink() {
        addLink(this.state.izena, this.state.lotura).then(() => {
            this.props.history.push('/loturak')
        })
    }

    render() {
        return (
            <div>
                <div className="field">
                    <label className="label">Izena</label>
                    <div className="control">
                        <input className="input" type="text" placeholder="Izena" value={this.state.izena} onChange={(event) => this.setState({ izena: event.target.value})}/>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Lotura</label>
                    <div className="control">
                        <input className="input" type="text" placeholder="Lotura" value={this.state.lotura} onChange={(event) => this.setState({lotura: event.target.value})} />
                    </div>
                </div>

                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-link" onClick={() => this.addLink()}>Gehitu</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(LoturaAdd)