import { UPDATE_BIDEOAK, UpdateBideoakAction } from '../actions/types'
import { Bideoa } from '../interfaces'

export default (state: Bideoa[] = [], action: UpdateBideoakAction) => {
    switch (action.type) {
        case UPDATE_BIDEOAK:
            return action.payload
        default:
            return state
    }
}