import { UPDATE_SPLASH, UPDATE_MAPA, UPDATE_EGOERA, UPDATE_HOME_BANNER, UPDATE_JAKINARAZPENAK_BANNER, UpdateBabesleaTypes } from '../actions/types'
import { Babesleak } from '../interfaces';

const INITIAL_STATE: Babesleak = {
    splash: '',
    mapa: '',
    egoera: '',
    home: '',
    jakinarazpenak: ''
};

export default (state = INITIAL_STATE , action: UpdateBabesleaTypes) => {
    switch (action.type) {
        case UPDATE_SPLASH:
            console.log(action);
            return { ...state, splash: action.payload }
        case UPDATE_MAPA:
            return { ...state, mapa: action.payload }
        case UPDATE_EGOERA:
            return { ...state, egoera: action.payload }
        case UPDATE_HOME_BANNER:
            return { ...state, home: action.payload }
        case UPDATE_JAKINARAZPENAK_BANNER:
            return { ...state, jakinarazpenak: action.payload }
        default:
            return state
    }
}