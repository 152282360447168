import React from 'react'
import { saveBideoa } from '../../firestore-actions'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import firebase from '../../firebase'

interface RouteParams {
    id: string
}
class BideoaEdit extends React.Component<RouteComponentProps<RouteParams>, { id: string, title: string, url: string, latitude: number, longitude: number  }>  {

    constructor(props: any) {
        super(props)

        const { id } = this.props.match.params

        this.state = {
            id: id,
            title: '',
            url: '',
            latitude: 0,
            longitude: 0
        }
    }

    componentDidMount() {
        firebase.firestore().collection('bideoak').doc(this.state.id).get().then(snapshot => {
            const data = snapshot.data()
            
            this.setState({
               title: data?.title,
               url: data?.url,
               latitude: data?.location.latitude,
               longitude: data?.location.longitude,
            })
        })
    }

    save() {
        saveBideoa(this.state.id, this.state.title, this.state.url, this.state.latitude, this.state.longitude).then(() => {
            this.props.history.push('/bideoak')
        })
    }

    render() {
        return (
            <div>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Titulua</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <input className="input" type="text" placeholder="Titulua" value={this.state.title} onChange={(event) => this.setState({ title: event.target.value})}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">URL</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <input className="input" type="text" placeholder="URL" value={this.state.url} onChange={(event) => this.setState({url: event.target.value})} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <label className="label">Latitude</label>
                            <div className="control">
                                <input className="input" type="text" placeholder="URL" value={this.state.latitude} onChange={(event) => this.setState({latitude: parseFloat(event.target.value)})} />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Longitude</label>
                            <div className="control">
                                <input className="input" type="text" placeholder="URL" value={this.state.longitude} onChange={(event) => this.setState({longitude: parseFloat(event.target.value)})} />
                            </div>
                        </div>
                    </div>
                </div> 

                <div className="field is-horizontal">
                    <div className="field-label"></div>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <button className="button is-link" onClick={() => this.save()}>Gorde</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(BideoaEdit)