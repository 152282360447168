import firebase from './firebase'

export const deleteLink = (link_id: string) => {
   return firebase.firestore().collection('loturak').doc(link_id).delete().catch(console.error) 
}

export const addLink = (izena: string, url: string) => {
    return firebase.firestore().collection('loturak').doc().set({
        izena,
        url,
        mota: 'URL'
    }).catch(console.error)
}

export const saveLink = (id: string, izena: string, url: string) => {
    return firebase.firestore().collection('loturak').doc(id).update({
        izena,
        url
    }).catch(console.error)
}

export const deleteJakinarazpena = (id: string) => {
   return firebase.firestore().collection('jakinarazpenak').doc(id).delete().catch(console.error) 
}

export const addJakinarazpena = (title: string, body: string, send: boolean) => {
    return firebase.firestore().collection('jakinarazpenak').doc().set({
        title,
        body,
        pending_send: send,
        sent: false,
        created: new Date()
    }).catch(console.error)
}

export const saveJakinarazpena = (id: string, title: string, body: string, send: boolean) => {
    return firebase.firestore().collection('jakinarazpenak').doc(id).update({
        title,
        body,
        pending_send: send
    }).catch(console.error)
}

export const deleteBideoa = (id: string) => {
    return firebase.firestore().collection('bideoak').doc(id).delete().catch(console.error);
}

export const addBideoa = (title: string, url: string, latitude: number, longitude: number) => {
    return firebase.firestore().collection('bideoak').doc().set({
        title,
        url,
        location: new firebase.firestore.GeoPoint(latitude, longitude)
    }).catch(console.error)
}

export const saveBideoa = (id: string, title: string, url: string, latitude: number, longitude: number) => {
    return firebase.firestore().collection('bideoak').doc(id).update({
        title,
        url,
        location: new firebase.firestore.GeoPoint(latitude, longitude)
    }).catch(console.error)
}

export const getOharra = (id: string) => {
    return firebase.firestore().collection('oharrak').doc(id).get();
}

export const saveOharra = (id: string, mezua: string, active: boolean) => {
    return firebase.firestore().collection('oharrak').doc(id).update({
        mezua,
        active
    }).catch(console.error);
}