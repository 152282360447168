import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAIrVe1tjlZO3b7-XEqPYIcfDeUk35u4Dk",
    authDomain: "korrika-2f25b.firebaseapp.com",
    databaseURL: "https://korrika-2f25b.firebaseio.com",
    projectId: "korrika-2f25b",
    storageBucket: "korrika-2f25b.appspot.com",
    messagingSenderId: "922904835657",
    appId: "1:922904835657:web:e0921ec16ce645a67cb198",
    measurementId: "G-PFJWDH952G"
}

firebase.initializeApp(firebaseConfig)

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

export default firebase